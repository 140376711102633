import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom"; 
import "../App.css";
import Progressbar from "./ProgressBar";
// import ProgresLoader from "./ProgressLoader";
import YouTubeVideo from "./YouTubeVideo";
import { useYouTubePlayer } from "./YouTubePlayerContext"; // Adjust path as necessary
import logo from "../Assets/images/brevy.svg";
import link from "../Assets/images/link.svg";
import pattern from "../Assets/images/pattern.png";
import left from "../Assets/images/left-pattern.png";
import time from "../Assets/images/time.svg";
import copy from "../Assets/images/copy.svg";
import copied from "../Assets/images/copied.svg";
import Summary from "./Summary";
import { useAuth } from "../contexts/authContext";
import { fetchIpAddress } from './Services/CaptureIP';
import Chatbot from './Chatbot';  // Adjust the path as necessary

/***************************************************************************************** */
/***************************************************************************************** */
/***************************************************************************************** */
const Environment = "prod"; // Set the environment here. Production: "prod", Development: "dev"
/***************************************************************************************** */
/***************************************************************************************** */
/***************************************************************************************** */

var apiURL = Environment == "dev" ? apiURL = "http://127.0.0.1:8080" : apiURL = "https://api.bevi.ai";

var webURL = Environment == "dev" ? webURL = "http://localhost:3000" : webURL = "https://bevi.ai";


const userObj = {
  name: "",
  email: "",
  photo: "",
  display_name: "",
  email_verified: "",
  firebase_uid: "",
  phone_number: "",
  active: true,
  free_trial: true,
  usage_count: 0,
  signup_datetime: "",
  last_login_datetime: "",
}

const emptyUser = {
  uid: "",
  name: "",
  email: "",
  photo: "",
  display_name: "",
  email_verified: "",
  firebase_uid: "",
  phone_number: "",
  active: true,
  free_trial: true,
  usage_count: 0,
  signup_datetime: "",
  last_login_datetime: "",
  metadata_creationTime: "2023-01-01 00:00:00",
  metadata_lastSignInTime: "2023-01-01 00:00:00",
}

function SummaryPage() {
  const { linkId } = useParams(); 
  const { currentUser : currentUsers } = useAuth();
  ////////console.log("Current User")
  ////////console.log(currentUser)
  const [user, setUser] = useState(userObj);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [transcript, setTranscript] = useState([]);
  const [actionableAdvice, setActionableAdvice] = useState({});
  const [videoKey, setVideoKey] = useState(0); 
  
  const updateVideoKey = () => {
    setVideoKey((prevKey) => prevKey + 1);
  };
  const [summary, setSummary] = useState("");
  const [buttonText, setButtonText] = useState('Share with Friends 🚀');
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);


  const [isOpen, setIsOpen] = useState(false);
  const chatButtonText = isOpen ? 'Close Chatbot 🤖' : 'Chat with Video 🤖';
  const [showSummary, setShowSummary] = useState(true);
  const summaryButtonText = showSummary ? 'Show Chat 🤖' : 'Show Summary 📄';
  const resultsRef = useRef(null);
  
  const toggleView = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    setShowSummary(!newIsOpen);
    
    if (newIsOpen) {
      // If opening the chat, scroll to 1/3rd down the page
      setTimeout(() => {
        const pageHeight = document.documentElement.scrollHeight;
        const viewportHeight = window.innerHeight;
        const scrollPosition = (pageHeight - viewportHeight) / 1.4;
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
      }, 100); // Small delay to ensure state has updated
    } else {
      // If closing the chat (i.e., showing summary), scroll to the top of the results section
      setTimeout(() => {
        if (resultsRef.current) {
          resultsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Small delay to ensure state has updated
    }
  };

 
  const [copyStatus, setCopyStatus] = useState({});

  const handleCopy = (textToCopy, index) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setCopyStatus((prevStatus) => ({
          ...prevStatus,
          [index]: true, // Mark this particular paragraph as copied
        }));

        setTimeout(() => {
          setCopyStatus((prevStatus) => ({
            ...prevStatus,
            [index]: false, // Reset the copied status after 2 seconds
          }));
        }, 2000);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };



  const [sentimentAnalysis, setSentimentAnalysis] = useState("");
  const [reliabilityScore, setReliabilityScore] = useState("");
  const [politicalLeaning, setPoliticalLeaning] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [mentionTopicContents, setMentionTopicContents] = useState({});
  const [adviceTopicsContents, setAdviceContentTopics] = useState([]);
  const [mentionTopics, setMentionTopics] = useState([]);
  const [adviceTopics, setAdviceTopics] = useState([]);
  const [videoId, setVideoId] = useState("");
  const [shareLink, setShareLink] = useState(window.location.href);
  const [chromaPath, setChromaPath] = useState(''); // New state for chroma path
  const { player, isPlayerReady } = useYouTubePlayer();
  const [ipAdd, setIpAddress] = useState('');
  const [urlCount, setUrlCount] = useState(null);

  var currentUser;
if(currentUsers){
  currentUser = currentUsers;
} else {  
currentUser = emptyUser;
}




  //get user IP address
  useEffect(() => {
    const getIp = async () => {
      const ip = await fetchIpAddress();
      setIpAddress(ip);
    };

    getIp();
  }, []); 

  useEffect(() => {
    const fetchUrlCount = async () => {
      if (ipAdd) {
        try {
          const response = await fetch(apiURL + '/api/get_url_count_by_ip', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ip: ipAdd , currentUser: currentUser}),
          });
          const data = await response.json();
          if (response.ok) {
            ////console.log('URL count:', data.count)
            setUrlCount(data.count);
          } else {
            console.error('Error fetching URL count:', data.error);
          }
        } catch (error) {
          console.error('Failed to fetch URL count:', error);
        }
      }
    };

    fetchUrlCount();
  }, [ipAdd, youtubeLink]);

  useEffect(() => {
    if (linkId) {
      fetchSummaryById(linkId);
    }
  }, [linkId]);

  useEffect(() => {
    const formatUserData = (currentUser, ipAdd) => {
        // Define only the properties that should always sync with Firebase
        const userData = {
            user_ip: ipAdd || "",
            firebase_uid: currentUser.uid || "",
            name: currentUser.displayName || "",
            display_name: currentUser.displayName || "",
            email: currentUser.email || "",
            email_verified: currentUser.emailVerified || false,
            photo_url: currentUser.photoURL || "",
            phone_number: currentUser.phoneNumber || "",
            signup_datetime: currentUser.metadata ? currentUser.metadata.creationTime : null,
            last_login_datetime: currentUser.metadata ? currentUser.metadata.lastSignInTime : null,
        };

        // If both email and user_ip are empty, return null to prevent API call
        if (!userData.email && !userData.user_ip) {
            //////console.log('Both email and user_ip are missing. No action taken.');
            return null;
        }

        // If signup_datetime or last_login_datetime are empty strings, set them to null
        if (userData.signup_datetime === '') {
            userData.signup_datetime = null;
        }
        if (userData.last_login_datetime === '') {
            userData.last_login_datetime = null;
        }

        // If it's the first sign-in, set default values
        if (userData.signup_datetime === userData.last_login_datetime) {
            userData.active = true;
            userData.free_trial = true;
            userData.usage_count = 0;
        }

        return userData;
    };

    // Function to upsert user data to the API
    const upsertUserData = async (formattedUserData) => {
        if (!formattedUserData) return; // Skip API call if data is null

        try {
            const response = await fetch(apiURL + '/api/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formattedUserData),
            });

            // Check if the request was successful
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            //////console.log('Upsert successful', data);
        } catch (error) {
            console.error('There was an error upserting the user:', error);
        }
    };

      //////console.log(ipAdd)
      const formattedUserData = formatUserData(currentUser, ipAdd); //Pass IP address here. If IP present, and no email, give 3 tries. If email is present, 5 API calls. If email is not present, 3 API calls.
      ////////console.log('Formatted User Data', formattedUserData)
      upsertUserData(formattedUserData);
      setUser(formattedUserData); // Optionally update state as well
   
  }, [currentUser, complete, ipAdd]); 



  const navigate = useNavigate(); // Initialize the navigate function
  

  const fetchTranscriptAndAdvice = async () => {
    console.log("heressasafafaf")
    setButtonText('Share with Friends 🚀')
    ////console.log(urlCount)
    ////console.log("here")
    ////console.log(currentUsers)
    if (urlCount >= 3 && !currentUsers) {
    // stripe account logic goes here!
      navigate('/register'); // Redirect to register page if user is not authenticated
      return;
    }

    setErrorMessage("");
    resetAdviceState();
    setLoading(false);
    setComplete(false);

    const youtubeUrlRegex =
/^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|playlist\?list=)|youtu\.be\/)([a-zA-Z0-9_-]+)(?:\?[^#\s]*|&[^#\s]*|#.*)?$/;

  const match = youtubeLink.trim().match(youtubeUrlRegex);
 console.log("Match", match);
  if (match && match[5]) {
    // match[5] contains the video ID or playlist ID
    const videoId = match[5];
  
    setVideoId(videoId);
    updateVideoKey();
  } else {
    setErrorMessage("Please enter a valid YouTube link 😊");
    return;
  }
  
  if (!youtubeLink.trim()) {
    setErrorMessage("No link was pasted :(");
    return;
  }
  
  if (!youtubeUrlRegex.test(youtubeLink.trim())) {
    setErrorMessage("Please enter a valid YouTube link 😊");
    return;
  }
  


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ youtube_url: youtubeLink, firebase_uid: currentUsers? currentUser.uid: "anonymous", ip: ipAdd, hello: "hello"}),
  };
  //////console.log('Request Options', requestOptions)

    // Fetching transcript and processing video as per the original setup
    try {
      setLoading(true);
    

      const processRes = await fetch(apiURL + '/api/process_video', requestOptions);
      if (!processRes.ok) {
        //////////console.log(JSON.stringify(processRes));
        throw new Error("No transcript found for this video 😔");
      }
     var processData = {};
     const processData_1 = await processRes.json(); // Parse the response body as JSON


     //////////console.log("Process Data 1");
     //////////console.log(JSON.stringify(processData_1));
     if (processData_1.video_summary_json == null) {
      processData = processData_1;
     } else{
      processData = processData_1.video_summary_json;
     }

      ////console.log("Process Data");
      ////console.log(JSON.stringify(processData));

      // Updating the handling logic here to dynamically process the structured JSON
      function extractTopicsAndContents(data, objectName, objectName2) {
        // Initialize arrays to hold the topics and their contents
        let topics = [];
        let topicContents = [];
      
        const targetObject = data[objectName] || data[objectName2];
        if (targetObject) {
          // Extract topics and contents from the target object
          for (const [key, value] of Object.entries(targetObject)) {
            topics.push(key);
            topicContents.push(value);
          }
        }

        return { topics, topicContents };
      }



      //mentions
      function extractMentionTopicsAndContents(data, objectName, objectName2) {
        // Initialize arrays to hold the topics and their contents
        let Mtopics = [];
        let MtopicContents = [];
   
        const targetObject = data[objectName] || data[objectName2];
        if (targetObject) {
          // Extract topics and contents from the target object
          for (const [key, value] of Object.entries(targetObject)) {
            Mtopics.push(key);
            MtopicContents.push(value);
          }
        }

        return { Mtopics, MtopicContents };
      }

      // Call the function for "all actionable advices"
      const { topics, topicContents } = extractTopicsAndContents(
        processData.summary,
        "all actionable advices",
        "all_actionable_advices"
      );
      //debug

      setAdviceTopics(topics || []);
      setAdviceContentTopics(topicContents || {});
      // Call the function for "all actionable advices"

      const { Mtopics, MtopicContents } = extractMentionTopicsAndContents(
        processData.summary,
        "all_mentions",
        "all mentions"
      );
      //debug

      setMentionTopics(Mtopics || []);
      setMentionTopicContents(MtopicContents || {});
      setSummary(processData.summary.summary || processData.summary.key_conclusions || processData.summary || processData.key_conclusions ||null);
      setActionableAdvice(processData.summary["all actionable advices"] || processData.summary["all_actionable_advices"] || {});
      setSentimentAnalysis(processData.summary.sentiment_analysis || null);
      setReliabilityScore(processData.summary.reliability_score || null);
      setPoliticalLeaning(processData.summary.political_leaning || null);
      setComplete(true);
      setLoading(false);
      ///////////////////////////////////////////////////////////
      const { link_id } = processData;
      setShareLink(webURL + `/summary/${link_id}`);
      //(`/summary/${link_id}`); //  to the new route with the link_id
      ///////////////////////////////////////////////////////////
    } catch (error) {
      console.error("There was an error processing the video:", error);
      setErrorMessage(
        error.message || "There was an error processing the video."
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSummaryById = async (id) => {
    try {
      const response = await fetch(apiURL + `/api/get_summary/${id}`);
      if (!response.ok) {
        throw new Error('Summary not found');
      }
      const data = await response.json();
      setYoutubeLink(data.video_url);

      const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|playlist\?list=)|youtu\.be\/)([a-zA-Z0-9_-]+)(?:\?[^#\s]*|&[^#\s]*|#.*)?$/;
      const match = data.video_url.trim().match(youtubeUrlRegex);
      if (match && match[5]) {
        const videoId = match[5];
        setVideoId(videoId);
        updateVideoKey();
      }

      const processData = data.summary;
      ////console.log('PMEEEEE' + JSON.stringify(data.summary.key_conclusions));
      function extractTopicsAndContents(data, objectName, objectName2) {
        let topics = [];
        let topicContents = [];
        const targetObject = data[objectName] || data[objectName2];
        if (targetObject) {
          for (const [key, value] of Object.entries(targetObject)) {
            topics.push(key);
            topicContents.push(value);
          }
        }
        return { topics, topicContents };
      }

      function extractMentionTopicsAndContents(data, objectName, objectName2) {

        //console.log('error?');
        let Mtopics = [];
        let MtopicContents = [];
        const targetObject = data[objectName] || data[objectName2];
        if (targetObject) {
          for (const [key, value] of Object.entries(targetObject)) {
            Mtopics.push(key);
            MtopicContents.push(value);
          }
        }
        //console.log('error!');
        return { Mtopics, MtopicContents };
      }

      const { topics, topicContents } = extractTopicsAndContents(
        processData,
        "all actionable advices",
        "all_actionable_advices"
      );
      setAdviceTopics(topics || []);
      setAdviceContentTopics(topicContents || {});

      const { Mtopics, MtopicContents } = extractMentionTopicsAndContents(
        processData,
        "all_mentions",
        "all mentions"
      );

      setMentionTopics(Mtopics || []);
      setMentionTopicContents(MtopicContents || {});


      //console.log('processData');
      //console.log(processData.key_conclusions);

      setSummary(processData.key_conclusions || processData.summary.summary || processData.summary.key_conclusions || processData.summary  ||null);

      setActionableAdvice(processData["all actionable advices"] || processData["all_actionable_advices"] || {});
      setSentimentAnalysis(processData.sentiment_analysis || null);
      setReliabilityScore(processData.reliability_score || null);
      setPoliticalLeaning(processData.political_leaning || null);
      setComplete(true);
      setButtonText('Share as Blog 🚀')
      setLoading(false);
    } catch (error) {
      console.error('Error fetching summary:', error);
      setErrorMessage('Error fetching summary');
    }
  };

  const seekTo = (timeInSeconds) => {
    if (player && isPlayerReady && player.seekTo) {
      player.seekTo(timeInSeconds);
      if (player.getPlayerState() !== 1) {
        player.playVideo();
      }
    }
  };

  const resetAdviceState = () => {
    setSummary("");
    setTranscript([]);
    setActionableAdvice({});
    setSentimentAnalysis("");
    setReliabilityScore("");
    setPoliticalLeaning("");
    setMentionTopics([]);
  };


  const copyShareLink = () => {
    navigator.clipboard.writeText(shareLink).then(
      () => {
        ////////console.log('Share link copied to clipboard');
        setButtonText('Link Copied ✅'); // Change button text setButtonText('Share with Friends 🚀')
      },
      (err) => {
        console.error('Failed to copy share link: ', err);
      }
    );
  };

  function formatDuration(totalSecondsFloat) {
    const totalSeconds = Math.floor(totalSecondsFloat);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  return (
    <div className="App">
      <div className="custom-container">
        <img src={pattern} alt="right-pattern" className="right-pattern"></img>
        <img src={left} alt="left-pattern" className="left-pattern"></img>
        {!summary && (
          <>
            <div className="app-name">
              <img src={logo} alt="logo"></img>Bevi
            </div>
            <div className="main-details">
              <h1>Video Summary</h1>
              <p>
                Save time on watching YouTube videos. Break down YouTube videos into
                parts and get key takeaways.
              </p>
              <div className="linear-lines">
                <div></div>
                <div className="middle-linear"></div>
                <div></div>
              </div>
            </div>
          </>
        )}
        <div className="content">
          <img src={link} alt="link" className="link"></img>
          <input
            type="text"
            id="youtubeLink"
            name="youtubeLink"
            value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)}
            className="youtube-input"
            placeholder="Paste YouTube video URL here! 🐇 "
          />
          <button onClick={fetchTranscriptAndAdvice} className="gradient-button">
            Summarize
          </button>
        </div>
        {loading ? (
          <Progressbar loading={loading} complete={complete} />
        ) : (
          <div></div>
        )}
  
        {errorMessage && <div className="error-message">{errorMessage}</div>}
  
        {(summary ||
          Object.keys(actionableAdvice).length > 0 ||
          sentimentAnalysis ||
          reliabilityScore ||
          politicalLeaning) && (
          
            <div className="results-section" ref={resultsRef}>
            <div className="sent-rely">
              <div className="sent">
                <p><b>Sentiment:</b> {sentimentAnalysis}</p>
              </div>
              <div className="sent" style={{paddingRight: 20}}>
                <p><b>Reliability Score: </b>{reliabilityScore}/10</p>
              </div>
            </div>
            <div style={{paddingTop: 0}}>
              <YouTubeVideo key={videoKey} videoKey={videoKey} videoId={videoId} />
              <div className="button-container">
                <button onClick={copyShareLink} className="gradient-button">
                  {buttonText}
                </button>
                <button
                  className="gradient-button gradient-buttons chatbot-toggle"
                  onClick={toggleView}
                >
                  {showSummary ? chatButtonText : summaryButtonText}
                </button>
              </div>
  
              {isOpen && (
                <div className="chat-container">
                  <Chatbot
                    videoURL={youtubeLink}
                    videoId={videoId}
                    chromaPath={chromaPath}
                    isOpen={isOpen}
                    player={player}
                    isPlayerReady={isPlayerReady}
                  />
                </div>
              )}
  
              {showSummary && (
                <div className="inner-results">
                  <h2 className="common-heading">Video Summary</h2>
                  <div className="details-container">
                    <img
                      src={copyStatus['summary'] ? copied : copy}
                      alt="copy"
                      className="copyy"
                      onClick={(e) => {
                        e.stopPropagation();
                        const textToCopy = `${summary}`;
                        handleCopy(textToCopy, 'summary');
                      }}
                    />
                    <Summary summary={summary} />
                  </div>
  
                  {adviceTopics.length > 0 && (
                    <h2 className="common-heading">Actionable Advice</h2>
                  )}
  
                  {adviceTopics &&
                    adviceTopics.map((topic, index) => (
                      <React.Fragment key={index}>
                        <div key={index} className="details-container">
                          <h3 className="common-sub">
                            {topic.charAt(0).toUpperCase() + topic.slice(1)}
                          </h3>
                          {adviceTopicsContents[index] &&
                          adviceTopicsContents[index].length > 0 ? (
                            adviceTopicsContents[index].map(
                              (content, contentIndex) => (
                                <div className="paragraph-data" key={contentIndex}>
                                  <div
                                    className="content-top-details"
                                    onClick={() => {
                                      seekTo(content.start_time);
                                      window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                      });
                                    }}
                                  >
                                    <img
                                      src={time}
                                      alt="time"
                                      className="time"
                                    ></img>
                                    <strong>
                                      <span style={{ color: '#531fff' }}>
                                        {formatDuration(content.start_time)}
                                      </span>
                                      {" " + content.name}:
                                    </strong>
                                    <img
                                      src={
                                        copyStatus[`${index}-${contentIndex}`]
                                          ? copied
                                          : copy
                                      }
                                      alt="copy"
                                      className="copy"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const textToCopy = `${content.name}: ${content.description}`;
                                        handleCopy(
                                          textToCopy,
                                          `${index}-${contentIndex}`
                                        );
                                      }}
                                    />
                                  </div>
                                  <p className="parad">{content.description}</p>
                                </div>
                              )
                            )
                          ) : (
                            <p>No content available for this topic.</p>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
  
                  {mentionTopics.length > 0 && (
                    <h2 className="common-heading">Highlights</h2>
                  )}
                  {mentionTopics &&
                    mentionTopics.map((topic, index) => (
                      <React.Fragment key={index}>
                        <div key={index} className="details-container">
                          <h3 className="common-sub">
                            {topic.charAt(0).toUpperCase() + topic.slice(1)}
                          </h3>
                          {mentionTopicContents[index] &&
                          mentionTopicContents[index].length > 0 ? (
                            mentionTopicContents[index].map(
                              (content, contentIndex) => (
                                <div className="parad" key={contentIndex}>
                                  <div
                                    className="content-top-details"
                                    onClick={() => {
                                      seekTo(content.start_time);
                                      window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                      });
                                    }}
                                  >
                                    <img
                                      src={time}
                                      alt="time"
                                      className="time"
                                    ></img>
                                    <strong>
                                      <span style={{ color: '#531fff' }}>
                                        {formatDuration(content.start_time)}
                                      </span>
                                      {" " + content.name}:
                                    </strong>
                                    <img
                                      src={
                                        copyStatus[`${index}-${contentIndex}`]
                                          ? copied
                                          : copy
                                      }
                                      alt="copy"
                                      className="copy"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const textToCopy = `${content.name}: ${content.description}`;
                                        handleCopy(
                                          textToCopy,
                                          `${index}-${contentIndex}`
                                        );
                                      }}
                                    />
                                  </div>
                                  {content.description}
                                </div>
                              )
                            )
                          ) : (
                            <p>No content available for this topic.</p>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SummaryPage;
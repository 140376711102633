import React, { createContext, useContext, useState } from 'react';

const YouTubePlayerContext = createContext();

export const useYouTubePlayer = () => useContext(YouTubePlayerContext);

export const YouTubePlayerProvider = ({ children }) => {
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false); // Add this line

  return (
    <YouTubePlayerContext.Provider value={{ player, setPlayer, isPlayerReady, setIsPlayerReady }}>
      {children}
    </YouTubePlayerContext.Provider>
  );
};

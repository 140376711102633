import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { doSignOut } from '../../firebase/auth';
import logo from '../../Assets/images/brevy.svg';
import rabbit from '../../Assets/images/Rabbit.jpeg';
import { FaUserCircle, FaDollarSign, FaGlobe, FaSignOutAlt, MdPublic } from 'react-icons/fa';

const Header = ({ ipAdd }) => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const { userLoggedIn, currentUser } = useAuth();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null); // Create a ref for the dropdown
    const buttonRef = useRef(null); // Create a ref for the image button

    // Function to toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    // Custom function to handle navigation and potentially refresh
    const handleNavigation = (path) => {
        if (location.pathname === path) {
            window.location.reload();
        } else {
            navigate(path);
        }
    };

    // Close the dropdown if clicked outside
    const handleClickOutside = (event) => {
        if (
            dropdownRef.current && !dropdownRef.current.contains(event.target) && 
            buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener when dropdown is open
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <nav className='brevy-nav'>
            <div className="nav-bar-wrapper">
                <div className="nav-bar-main">                
                    <a href="/home">
                        <div className="app-name">
                            <img src={logo} alt="logo" />Bevi
                        </div>
                    </a>
                    <div className="login-btns">
                        {
                            userLoggedIn
                                ?
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '0px', boxSizing: 'border-box', width: '100%' }}>
                                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                                            <div 
                                                ref={buttonRef}
                                                style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden', backgroundColor: '#ccc', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }} 
                                                onClick={toggleDropdown}
                                            >
                                                <img src={currentUser.photoURL ? currentUser.photoURL : rabbit} alt="user" style={{ width: '100%', height: 'auto' }} />
                                            </div>

                                            {/* Dropdown Menu */}
                                            {isDropdownOpen && (
                                                <div 
                                                    ref={dropdownRef} // Attach the ref to the dropdown
                                                    style={{
                                                        position: 'absolute',
                                                        top: '45px',
                                                        right: '0',
                                                        backgroundColor: 'white',
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                                        borderRadius: '8px',
                                                        overflow: 'hidden',
                                                        zIndex: 1000
                                                    }}
                                                >
                                                          <ul style={{ listStyleType: 'none', padding: '10px', margin: '0', minWidth: '250px' }}>
                                                        {/* <li style={{ fontSize: "14.5px",padding: ' 4px 6px', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => handleNavigation('/manage-account')}>
                                                            <FaAddressCard style={{ marginRight: '10px' }} />  Manage Account 
                                                        </li> */}
                                                        <li style={{fontSize: "14.5px", padding: ' 4px 6px', cursor: 'default', display: 'flex', alignItems: 'center' }}>
                                                            <FaUserCircle style={{ marginRight: '10px' }} />  {currentUser.displayName ? currentUser.displayName : currentUser.email}
                                                        </li>
                                                        <li style={{fontSize: "14.5px", padding: ' 4px 6px', cursor: 'default', display: 'flex', alignItems: 'center' }}>
                                                            <FaGlobe style={{ marginRight: '10px' }} /> IP:  {ipAdd}
                                                        </li>
                                                        {/* <li style={{fontSize: "14.5px", padding: ' 4px 6px', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => handleNavigation('/affiliate')}>
                                                            <FaDollarSign style={{ marginRight: '10px' }} />  Become an Affiliate 
                                                        </li> */}
                                                        <li style={{fontSize: "14.5px", padding: ' 4px 6px', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => { doSignOut().then(() => { navigate('/login') }) }}>
                                                            <FaSignOutAlt style={{ marginRight: '10px' }} /> Logout
                                                        </li>
                                                    </ul>
                                                    <div style={{ padding: '10px', fontSize: '12px', textAlign: 'center', borderTop: '.5px solid #ccc' }}>
                                                        <Link to="/privacy-policy">Privacy Policy</Link>  |  
                                                        <Link to="/terms-and-conditions"> Terms and Conditions</Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <button className='header-btn' onClick={() => handleNavigation('/login')}>Login</button>
                                    <button className='gradient-button' onClick={() => handleNavigation('/register')}>Register New Account</button>
                                </>
                        }
                    </div>
                </div>
                
            </div>
        </nav>
    );
}

export default Header;

import React from 'react';
import '../App.css'; // Ensure this CSS file contains the required styles

const PrivacyPolicy = () => {
    return (
        <div className="terms-container">
            <h1><b>Bevi Privacy Policy</b></h1>
            <p><strong>Last updated: Sept 1, 2024</strong></p>

            
            <p>This Privacy Policy describes Bevi’s policies and procedures on the collection, use, and disclosure of your information when you use the Service and tells you about your privacy rights and how the law protects you.</p>

            <p>We use your personal data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>

            <p>We are committed to protecting your personal data and ensuring its privacy, security, and integrity. Bevi will only collect and use your personal data when it is necessary and relevant for the purposes of providing and improving our Service. We do not sell, trade, or otherwise transfer your personal information to outside parties.</p>


            <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

            <p>In the event of a data breach, we will promptly notify you and any applicable regulatory bodies of the breach in accordance with applicable laws and regulations.</p>

            <p>By continuing to use our Service, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy, including our practices regarding the collection, use, and disclosure of your personal data.</p>

            <h2>Interpretation and Definitions</h2>

            <h3>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>

            <h4>1. Account</h4>
            <p>Means a unique account created for you to access our Service or parts of our Service.</p>

            <h4>2. Affiliate</h4>
            <p>Means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.</p>

            <h4>3. Company</h4>
            <p>Referred to as either "the Company," "We," "Us," or "Our" in this Agreement refers to Bevi, Inc., accessible from <a style={{ color: 'blue' }} href="https://Bevi.ai">https://Bevi.ai</a>.</p>

            <h4>4. Cookies</h4>
            <p>Are small files that are placed on your computer, mobile device, or any other device by a website, containing the details of your browsing history on that website among its many uses.</p>

            <h4>5. Country</h4>
            <p>Refers to California, United States.</p>

            <h4>6. Device</h4>
            <p>Means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</p>

            <h4>7. Personal Data</h4>
            <p>Is any information that relates to an identified or identifiable individual.</p>

            <h4>8. Service</h4>
            <p>Refers to the Website.</p>

            <h4>9. Service Provider</h4>
            <p>Means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service, or to assist the Company in analyzing how the Service is used.</p>

            <h4>10. Usage Data</h4>
            <p>Refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>

            <h4>11. Website</h4>
            <p>Refers to Bevi, accessible from <a style={{ color: 'blue' }} href="https://Bevi.ai">https://Bevi.ai</a>.</p>

            <h4>12. You</h4>
            <p>Means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>

            {/* Add other sections here following the same pattern */}

        </div>
    );
};

export default PrivacyPolicy;

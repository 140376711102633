import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Assets/images/brevy.svg';

const Footer = () => {
    return (
        <div className="main-footer">
            <div className="custom-container">
                <div className="footer-wrapper">
                    <div className="upper-footer">
                        <a href="/home">
                            <div className="app-name">
                                <img src={logo} alt="logo" />Bevi
                            </div>
                        </a>

                        <div className="services-footer">
                            <h3>👋 <b>Contact:</b></h3>
                            
                                <p>hello@bevi.ai</p>
                                {/** <li>Join our Community</li> */}
                           
                        </div>
                           { /**<div class="subscription">
                            <h3>Subscribe to our newsletter.</h3>
<h6>Sign up for our newsletter to stay up to date on the latest features and releases. We promise not to spam you.</h6>
                            <div class="sub-input">
                                <input type='text' placeholder='Enter your Email'></input>
                
                            </div>
                            <button class="sub-button" onClick={()=> {}}>Subscribe</button>
    </div> **/}
                        
                    </div>

                    
                    <div className="lower-footer" >
                    <div className="footer-links">
                            <Link to="/privacy-policy">Privacy Policy</Link> | 
                            <Link to="/terms-and-conditions">Terms and Conditions</Link> 
                           
                        </div>
                        <p>© 2024 Bevi. All rights reserved.</p>
                      
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;





import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure your CSS file contains the required styles
import MainContent from './Components/MainContent';
import Login from "./Components/auth/login";
import Header from "./Components/header";
import Register from "./Components/auth/register";
import { AuthProvider } from "./contexts/authContext";
import { useRoutes, Navigate } from "react-router-dom";
import Footer from "./Components/footer/footer";
import SummaryPage from "./Components/SummaryPage"; 
import PrivacyPolicy from './Components/PrivacyPolicy'; // Import the PrivacyPolicy component
import ProtectedRoute from './Components/ProtectedRoute'; // Import the ProtectedRoute component
import TermsAndConditions from './Components/TermsAndConditions'; // Import the TermsAndConditions component
import { fetchIpAddress } from './Components/Services/CaptureIP';




 
  

function App() {
  const [ipAdd, setIpAddress] = useState('');



   //get user IP address
   useEffect(() => {
    const getIp = async () => {
      const ip = await fetchIpAddress();
      setIpAddress(ip);
    };

    getIp();
  }, []); 

  const routesArray = [
    {
      path: "*",
      element: <Navigate to="/home" />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
      
    },
    {
      path: "/home",
      element: (
       
          <MainContent />
       
      ),
    },
    {
      path: "/terms-and-conditions", // Route for Terms and Conditions
      element: 
      (
      <TermsAndConditions />

      ),
    },
    {
      path: "/privacy-policy", // Route for Terms and Conditions
      element: 
      (
      <PrivacyPolicy />

      ),
    },
    {
      path: "/summary/:linkId", // New route
      element: (
   
          <SummaryPage />
      
      ),
    },
  ];

  let routesElement = useRoutes(routesArray);

  return (
    <AuthProvider>
      <Header ipAdd={ipAdd}/>
      <div className="w-full flex flex-col">{routesElement}</div>
      <Footer />
    </AuthProvider>
  );
}

export default App;

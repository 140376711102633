import React from 'react';
import '../App.css'; // Ensure this CSS file contains the required styles

const TermsAndService = () => {
    return (
        <div className="terms-container">
            <h1><b>Bevi Terms of Service</b></h1>
            <p><strong>Last updated: Sept 1st, 2024</strong></p>

            <p>Welcome to Bevi, an innovative productivity app designed to summarize YouTube videos and allow you to interact with the content through AI chat ("Service"). This Service is provided by Bevi ("we", "us", or "our"). By accessing or using our Service, you agree to be bound by these Terms of Service ("Terms") and our Privacy Policy, accessible here. Please read these Terms carefully before using our Service.</p>

            <h2>1. Service Overview</h2>
            <p>Bevi offers both a free version of our product and a paid subscription model that provides increased limits and additional features. The Service allows users to personalize and interact with content in new and engaging ways.</p>

            <h2>2. Subscription and Fees</h2>
            <p>The free version of Bevi offers limited access to features and capabilities. Users can subscribe to a paid version of Bevi for additional benefits and increased limits. Subscription fees and specifics will be provided at the time of subscription.</p>

            <h2>3. Account Registration</h2>
            <p>To access certain features of the Service, you may be required to create an account. You agree to provide accurate and complete information and to keep this information updated. You are responsible for safeguarding your account and for all activities that occur under your account.</p>

            <h2>4. User Conduct</h2>
            <p>Users of Bevi are expected to use the Service responsibly. The following are strictly prohibited:</p>
            <ul>
                <li>The use of profanity in edited text or in any content generated within the Service.</li>
            </ul>
            <p>Violations of these conduct guidelines may result in suspension or termination of your account.</p>

            <h2>5. Content Ownership</h2>
            <p>Bevi does not own rights for any of the Video Contents uploaded to the platform but we do have rights to any of the AI generated content. By using the Service, you grant us a worldwide, non-exclusive license to use, modify, perform, display, reproduce, and distribute your content for the purpose of operating and improving our Service. If you want to keep your content private, you can do so by contacting us to let us know at 
            @Bevi.ai.</p>

            <h2>6. Termination</h2>
            <p>We reserve the right to suspend or terminate your account and access to the Service if you are found to be in violation of these Terms, including but not limited to, breaching user conduct rules.</p>

            <h2>7. Dispute Resolution</h2>
            <p>If you have any disputes or concerns regarding the Service, please contact us at hello@Bevi.com. We will make every effort to resolve your concerns.</p>

            <h2>8. Limitation of Liability</h2>
            <p>Bevi shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>

            <h2>9. Amendments to the Terms</h2>
            <p>We reserve the right to modify these Terms at any time. You will be notified of any changes through the Service or via email. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.</p>

            <h2>10. Governing Law</h2>
            <p>These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which Bevi is registered, without regard to its conflict of law provisions.</p>
            <p>By using Bevi, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>

            <h2>11. Privacy Policy</h2>
            <p>You can see our privacy policy by visiting this page.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about these terms of service, you can contact us:</p>
            <p>By email: hello@Bevi.ai</p>
        </div>
    );
};

export default TermsAndService;
 
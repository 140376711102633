import React, { useState, useEffect } from 'react';
import load from '../Assets/images/loading.svg';
import loaded from '../Assets/images/loaded.svg';
import pattern from "../Assets/images/pattern.png";
import left from "../Assets/images/left-pattern.png";

export default function Progressbar({ loading, complete }) {
  const [filled, setFilled] = useState(0);

  useEffect(() => {
    let timer;
    if (loading && !complete) {
      const incrementAmount = 95 / 400;
      timer = setInterval(() => {
        setFilled((currentFilled) => {
          if (currentFilled < 98) {
            return currentFilled + incrementAmount;
          } else {
            clearInterval(timer);
            return currentFilled;
          }
        });
      }, 100);
    } else if (complete) {
      clearInterval(timer);
      setFilled(100);
    }

    return () => clearInterval(timer);
  }, [loading, complete]);

  const [loadingStatus, setLoadingStatus] = useState([true, true, true, true, true, true]);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoadingStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[0] = false;
        return newStatus;
      });
    }, 2000);

    const timer2 = setTimeout(() => {
      setLoadingStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[1] = false;
        return newStatus;
      });
    }, 6000);

    const timer3 = setTimeout(() => {
      setLoadingStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[2] = false;
        return newStatus;
      });
    }, 7000);

    const timer4 = setTimeout(() => {
      setLoadingStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[3] = false;
        return newStatus;
      });
    }, 9000);

    const timer5 = setTimeout(() => {
      setLoadingStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[4] = false;
        return newStatus;
      });
    }, 12000);

    const timer6 = setTimeout(() => {
      setLoadingStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[5] = false;
        return newStatus;
      });
    }, 70000);

    const timer7 = setTimeout(() => {
      setShowLoader(false); // Hide the loader after 8 seconds
    }, 100000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
      clearTimeout(timer6);
      clearTimeout(timer7);
    };
  }, []);

  return (
    
    <div className="loader-container">
      <img src={pattern} className="right-pattern" alt='right-pattern'></img>
      <img src={left} className="left-pattern" alt='left-pattern'></img>
      <div className="loader-wrapper">
      {showLoader ? (
        <div>
      <h2>Analyzing Your Video Content…</h2>
      {loadingStatus.map((status, index) => (
        <div key={index} className="loader-txt">
          <p>
            {index === 0 && "Transcribing your video"}
            {index === 1 && "Analyzing content"}
            {index === 2 && "Parsing key points"}
            {index === 3 && "Determining reliability"}
            {index === 4 && "Completing final checks"}
            {index === 5 && "Creating your notes"}
          </p>
          {status ? (
            <img src={load} alt="loading" className="loading-img" />
          ) : (
            <img src={loaded} alt="loaded" className="loaded-img" />
          )}
        </div>
      ))}
      <p className="loader-end-txt">Parsing video from the page… Long videos may take a few seconds to a minute to parse.</p>
      </div>
      
      ) : (
        <div className="another-div">
          <img src={load} alt="loading" className="seperate-loading" />
        </div>
      )}
      </div>
    </div>
  );
}

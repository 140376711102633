import axios from 'axios';

export const fetchIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Failed to fetch IP address:', error);
    return null;
  }
};
